export const environment = {
  production: false,

  api: {
    endpoint: 'https://api.tatamiapp.dev/api',
    maxWaiting: 10000,
    authHeader: 'Authorization',
  },

  rollbar: {
    accessToken: '4fb56a61e88a40639dda8d83a14eda97',
    environment: 'development',
    enabled: true,
  },

  availableLanguages: ['en'],
};
