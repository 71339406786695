<div
  class="c-toast"
  [ngClass]="'c-toast--' + toast.type"
  [class.c-toast--hidden]="!toast.visible"
>
  <ion-icon
    *ngIf="toast.icon"
    [name]="toast.icon"
    class="c-toast__icon"
  ></ion-icon>
  <div class="c-toast__label">{{ toast?.message }}</div>
  <button class="c-toast__button" (click)="handleClick($event)">
    <ion-icon name="close"></ion-icon>
  </button>
</div>
