import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { SessionRepository } from './session.repository';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { LoginBody, SessionResponse } from './session.interface';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class SessionService {
  constructor(
    private sessionRepository: SessionRepository,
    private http: HttpClient,
    private router: Router,
  ) {}

  login(body: LoginBody) {
    return this.http
      .post<SessionResponse>(
        `${environment.api.endpoint}/kiosk/auth/login`,
        body,
      )
      .pipe(
        tap((session) => {
          this.sessionRepository.update({ session });
        }),
      );
  }

  logout() {
    this.http
      .post(`${environment.api.endpoint}/kiosk/auth/logout`, {})
      .subscribe();

    this.router.navigate(['/login']);
    this.sessionRepository.update({ session: undefined });
  }
}
