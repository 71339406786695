import { Inject, Injectable } from '@angular/core';
import * as Rollbar from 'rollbar';
import { RollbarService } from '../rollbar-service/rollbar.service';

import { environment } from '../../../../environments/environment';
import { appVersion } from 'src/app/app.version';

// Default list from Rollbar Docs: https://docs.rollbar.com/docs/rollbarjs-configuration-reference
const defaultScrubFields = [
  'pw',
  'pass',
  'passwd',
  'password',
  'secret',
  'confirm_password',
  'confirmPassword',
  'password_confirmation',
  'passwordConfirmation',
  'access_token',
  'accessToken',
  'secret_key',
  'secretKey',
  'secretToken',
  'cc-number',
  'card number',
  'cardnumber',
  'cardnum',
  'ccnum',
  'ccnumber',
  'cc num',
  'creditcardnumber',
  'credit card number',
  'newcreditcardnumber',
  'new credit card',
  'creditcardno',
  'credit card no',
  'card#',
  'card #',
  'cc-csc',
  'cvc2',
  'cvv2',
  'ccv2',
  'security code',
  'card verification',
  'name on credit card',
  'name on card',
  'nameoncard',
  'cardholder',
  'card holder',
  'name des karteninhabers',
  'card type',
  'cardtype',
  'cc type',
  'cctype',
  'payment type',
  'expiration date',
  'expirationdate',
  'expdate',
  'cc-exp',
];

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  public async initialize() {
    if (this.isEnabled()) {
      let codeVersion = appVersion;

      this.rollbar.configure({
        scrubFields: [...defaultScrubFields, 'token', 'email'],
        scrubTelemetryInputs: true,
        captureIp: 'anonymize',
        payload: {
          code_version: codeVersion,
        },
      });
    }
  }

  public setUserId(userId: string): void {
    if (this.isEnabled()) {
      this.rollbar.configure({
        payload: {
          person: {
            id: userId,
          },
        },
      });
    }
  }

  public debug(payload: any): void {
    if (this.isEnabled()) {
      this.rollbar.debug(payload);
    }
  }

  public info(payload: any): void {
    if (this.isEnabled()) {
      this.rollbar.info(payload);
    }
  }

  public warning(message: string, args: any): void {
    if (this.isEnabled()) {
      this.rollbar.warning(message, args);
    }
  }

  public error(payload: any): void {
    if (this.isEnabled()) {
      this.rollbar.error(payload?.message || payload);
    }
  }

  public critical(payload: any): void {
    if (this.isEnabled()) {
      this.rollbar.error(payload?.message || payload);
    }
  }

  private isEnabled() {
    return environment.rollbar.enabled;
  }
}
