import { createStore, select, withProps } from '@ngneat/elf';
import { Injectable } from '@angular/core';
import { SessionResponse } from './session.interface';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';

interface SessionState {
  session: SessionResponse | undefined;
}

const initialState: SessionState = {
  session: undefined,
};

const store = createStore(
  { name: 'session' },
  withProps<SessionState>(initialState),
);

export const persist = persistState(store, {
  key: 'kiosk-session',
  storage: localStorageStrategy,
});

@Injectable({ providedIn: 'root' })
export class SessionRepository {
  isLoggedIn$ = store.pipe(select((state) => state.session?.token));

  session$ = store.pipe(select((state) => state.session));

  isLoggedIn(): boolean {
    return Boolean(store.getValue().session?.token);
  }

  session(): SessionResponse | undefined {
    return store.getValue().session;
  }

  update(session: Partial<SessionState>) {
    store.update((state) => ({ ...state, ...session }));
  }
}
