import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './core/guards/auth/auth-guard.service';
import { NoAuthGuardService } from './core/guards/no-auth/no-auth-guard.service';

/*
  Please check the article below for understanding how to structure modules
  https://medium.com/swlh/angular-organizing-features-and-modules-e582611a720e
*/

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'example',
    loadChildren: () =>
      import('./modules/example-feature/example-feature.module').then(
        (m) => m.HomePageModule,
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [NoAuthGuardService],
  },
  {
    path: 'class-list',
    loadChildren: () =>
      import('./modules/class-list/class-list.module').then(
        (m) => m.ClassListModule,
      ),
    canActivate: [AuthGuardService],
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
